<template>
  <div class="audience">
    <div class="audienceForm">
      <el-tabs
        v-model="activeTab"
        type="card"
        :editable="isCustomSale"
        @edit="handleTabsEdit"
        @tab-click="handleClick"
        :before-leave="tabsLeave"
      >
        <el-tab-pane
          :key="index"
          v-for="(item, index) in editableTabs"
          :name="index + 1 + ''"
        >
          <span slot="label"
            ><i
              class="el-icon-warning"
              style="color: #e6a23c; margin-right: 5px"
              v-show="
                (!contentList[index].geo_locations || !contentList[index].geo_locations.length) &&
                (!contentList[index].geo_locations_region || !contentList[index].geo_locations_region.length) &&
                (!contentList[index].geo_locations_city || !contentList[index].geo_locations_city.length)
              "
            ></i
            >{{ contentList[index].flexible_spec | audienceTabName(index) }}</span
          >
          <!-- 受众表单 -->
          <el-form
            :model="contentList[index]"
            :rules="rules"
            ref="ruleForm"
            label-width="120px"
            class="demo-ruleForm"
          >
            <el-form-item
              label="从已存的受众："
              prop="name"
              v-if="myAudience.length && isCustomSale"
            >
              <!-- <el-input v-model="contentList[index].audience"></el-input> -->
              <el-dropdown @command="handleCommand">
                <span class="el-dropdown-link">
                  {{ contentList[activeIndex].audienceName }} <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    :command="audience.id"
                    v-for="audience in myAudience"
                    :key="audience.id"
                    >{{ audience.name }}
                  </el-dropdown-item>
                  <el-dropdown-item
                    divided
                    :command="-1"
                    >管理受众列表</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </el-form-item>
            <el-form-item label="地区：">
              <div
                class=""
                style="display: flex"
              >
                <el-select
                  v-model="contentList[index].geo_locations"
                  placeholder="请选择"
                  multiple
                  filterable
                  clearable
                  style="width: 100%"
                  @change="selectCountry"
                  @visible-change="beforeSelectCountry"
                >
                  <el-option-group
                    v-for="(group, i) in selectGeoLocations"
                    :key="i"
                    :label="group.label"
                  >
                    <el-option
                      v-for="item in group.options"
                      :key="item.key"
                      :label="item.name"
                      :value="item.key"
                      :disabled="item.disabled"
                    >
                    </el-option>
                  </el-option-group>
                </el-select>
                <el-button
                  type="text"
                  v-show="adSeriesInfo && adSeriesInfo.site && adSeriesInfo.site.countryCode"
                  @click="selectDefaultCountry(index)"
                  >自动填充</el-button
                >
              </div>
            </el-form-item>
            <div v-if="isCustomSale">
              <el-form-item
                label="省份："
                v-show="smart_promotion_type != 'AUTOMATED_SHOPPING_ADS'"
              >
                <el-row>
                  <el-col :span="16">
                    <el-select
                      v-model="contentList[index].geo_locations_region"
                      placeholder="请选择"
                      multiple
                      filterable
                      clearable
                      style="width: 100%"
                      value-key="key"
                    >
                      <!-- <el-option v-for="item in regionsList" :key="item.key" :label="item.name" :value="item.key"> </el-option> -->
                      <el-option-group
                        v-for="group in regionsList"
                        :key="group.country.key"
                        :label="group.country.name"
                      >
                        <el-option
                          v-for="(item, index) in group.regions"
                          :key="index"
                          :label="item.name"
                          :value="item"
                          :disabled="group.disabled || item.disabled"
                        ></el-option>
                      </el-option-group>
                    </el-select>
                  </el-col>
                  <el-col :span="8">
                    <region-group
                      :list="contentList[index].geo_locations_region"
                      @set-regions="setRegions"
                      savedType="GEO_REGION"
                      :geos="contentList[index].geo_locations || []"
                    ></region-group>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-form-item
                label="城市："
                v-show="smart_promotion_type != 'AUTOMATED_SHOPPING_ADS'"
              >
                <el-row>
                  <el-col :span="16">
                    <el-select
                      v-model="contentList[index].geo_locations_city"
                      placeholder="请选择"
                      multiple
                      filterable
                      clearable
                      style="width: 100%"
                      value-key="key"
                    >
                      <!-- <el-option v-for="item in cityList" :key="item.key" :label="item.name" :value="item.key"> </el-option> -->
                      <el-option-group
                        v-for="group in cityList"
                        :key="group.country.key"
                        :label="group.country.name"
                      >
                        <el-option
                          v-for="(item, index) in group.cities"
                          :key="index"
                          :label="item.name"
                          :value="item"
                          :disabled="group.disabled"
                        ></el-option>
                      </el-option-group>
                    </el-select>
                  </el-col>
                  <el-col :span="8">
                    <region-group
                      :list="contentList[index].geo_locations_city"
                      @set-regions="setCity"
                      savedType="GEO_CITY"
                      :geos="contentList[index].geo_locations || []"
                    ></region-group>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-form-item
                label=""
                prop="region"
                v-show="smart_promotion_type != 'AUTOMATED_SHOPPING_ADS'"
              >
                <el-dropdown @command="handlePeopleGroup">
                  <span class="el-dropdown-link">
                    {{ contentList[activeIndex].peopleGroup }} <i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      :command="people.id"
                      v-for="people in peopleGroup"
                      :key="people.id"
                      >{{ people.name }}
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
                <el-button
                  @click="excludeCountry"
                  size="mini"
                  style="margin-left: 50px"
                  type="text"
                  >排除地区
                </el-button>
              </el-form-item>
              <el-form-item
                label="排除地区："
                prop="region"
                v-if="contentList[index].excludeCountryShow"
                v-show="smart_promotion_type != 'AUTOMATED_SHOPPING_ADS'"
              >
                <el-select
                  v-model="contentList[index].excluded_geo_locations"
                  placeholder="请选择"
                  multiple
                  filterable
                  clearable
                  style="width: 100%"
                >
                  <el-option-group
                    v-for="group in selectExcludeGeoLocations"
                    :key="group.label"
                    :label="group.label"
                  >
                    <el-option
                      v-for="item in group.options"
                      :key="'ex_' + item.key"
                      :label="item.name"
                      :value="item.key"
                      :disabled="item.exdisabled"
                    >
                    </el-option>
                  </el-option-group>
                </el-select>
              </el-form-item>
              <el-form-item
                label="排除省份："
                prop="region"
                v-if="contentList[index].excludeCountryShow"
                v-show="smart_promotion_type != 'AUTOMATED_SHOPPING_ADS'"
              >
                <el-row>
                  <el-col :span="16">
                    <el-select
                      v-model="contentList[index].excluded_geo_locations_regions"
                      placeholder="请选择"
                      multiple
                      filterable
                      clearable
                      style="width: 100%"
                      value-key="key"
                    >
                      <el-option-group
                        v-for="group in excludeRegionsList"
                        :key="group.country.name"
                        :label="group.country.name"
                      >
                        <el-option
                          v-for="item in group.regions"
                          :key="'ex_' + item.key"
                          :label="item.name"
                          :value="item"
                          :disabled="item.disabled || group.disabled"
                        >
                        </el-option>
                      </el-option-group>
                    </el-select>
                  </el-col>
                  <el-col :span="8">
                    <region-group
                      :list="contentList[index].excluded_geo_locations_regions"
                      @set-regions="setExcludeRegion"
                      savedType="EXCLUDED_GEO_REGION"
                      :geos="[]"
                    ></region-group>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-form-item
                label="排除城市："
                prop="region"
                v-if="contentList[index].excludeCountryShow"
                v-show="smart_promotion_type != 'AUTOMATED_SHOPPING_ADS'"
              >
                <el-row>
                  <el-col :span="16">
                    <el-select
                      v-model="contentList[index].excluded_geo_locations_cities"
                      placeholder="请选择"
                      multiple
                      filterable
                      clearable
                      style="width: 100%"
                      value-key="key"
                    >
                      <el-option-group
                        v-for="group in excludedCityList"
                        :key="group.country.name"
                        :label="group.country.name"
                      >
                        <el-option
                          v-for="item in group.cities"
                          :key="'ex_' + item.key"
                          :label="item.name"
                          :value="item"
                          :disabled="item.disabled || group.disabled"
                        >
                        </el-option>
                      </el-option-group>
                    </el-select>
                  </el-col>
                  <el-col :span="8">
                    <region-group
                      :list="contentList[index].excluded_geo_locations_cities"
                      @set-regions="setExcludeCity"
                      savedType="EXCLUDED_GEO_CITY"
                      :geos="[]"
                    ></region-group>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-form-item
                label="排除下级城市："
                prop="region"
                v-if="contentList[index].excludeCountryShow"
                v-show="smart_promotion_type != 'AUTOMATED_SHOPPING_ADS'"
              >
                <el-select
                  v-model="contentList[index].excluded_geo_locations_subcities"
                  placeholder="请选择"
                  multiple
                  filterable
                  clearable
                  style="width: 100%"
                >
                  <el-option-group
                    v-for="group in excludedSubCityList"
                    :key="group.country.key"
                    :label="group.country.name"
                  >
                    <el-option
                      v-for="item in group.cities"
                      :key="'ex_' + item.key"
                      :label="item.name"
                      :value="item.key"
                      :disabled="item.disabled || group.disabled"
                    >
                    </el-option>
                  </el-option-group>
                </el-select>
              </el-form-item>
              <el-form-item
                label="排除城镇："
                prop="region"
                v-if="contentList[index].excludeCountryShow"
                v-show="smart_promotion_type != 'AUTOMATED_SHOPPING_ADS'"
              >
                <el-row>
                  <el-col :span="16">
                    <el-select
                      v-model="contentList[index].excluded_geo_locations_places"
                      placeholder="请选择"
                      multiple
                      filterable
                      clearable
                      style="width: 100%"
                      value-key="key"
                    >
                      <el-option-group
                        v-for="group in excludedPlacesList"
                        :key="group.country.key"
                        :label="group.country.name"
                      >
                        <el-option
                          v-for="item in group.places"
                          :key="'ex_' + item.key"
                          :label="item.name"
                          :value="item"
                          :disabled="item.disabled || group.disabled"
                        >
                        </el-option>
                      </el-option-group>
                    </el-select>
                  </el-col>
                  <el-col :span="8">
                    <el-input
                      placeholder="请输入0-80的整数"
                      v-model="contentList[index].excluded_geo_locations_place_radius"
                      type="number"
                      :min="1"
                      :max="80"
                    >
                      <template slot="append">公里</template>
                    </el-input>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-form-item
                label="语言："
                prop="locals"
                v-show="smart_promotion_type != 'AUTOMATED_SHOPPING_ADS'"
              >
                <el-select
                  v-model="contentList[index].locales"
                  placeholder="请选择语言"
                  multiple
                  clearable
                  filterable
                  style="width: 100%"
                  v-if="initData.targeting"
                >
                  <el-option
                    :label="local.name"
                    :value="local.key"
                    v-for="local in initData.targeting.TargetingLocale"
                    :key="local.key"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label="性别："
                prop="sex"
                v-show="smart_promotion_type != 'AUTOMATED_SHOPPING_ADS'"
              >
                <el-radio-group
                  v-model="contentList[index].genders"
                  size="small"
                >
                  <el-radio-button label="all">全部</el-radio-button>
                  <el-radio-button label="M">男</el-radio-button>
                  <el-radio-button label="F">女</el-radio-button>
                </el-radio-group>
              </el-form-item>
              <el-form-item
                label="年龄："
                v-show="smart_promotion_type != 'AUTOMATED_SHOPPING_ADS'"
              >
                <el-col :span="5">
                  <el-form-item prop="date1">
                    <el-select
                      v-model="contentList[index].age_min"
                      placeholder="请选择"
                      filterable
                      clearable
                      style="width: 100%"
                    >
                      <el-option
                        :label="age"
                        :value="age"
                        v-for="age in contentList[index].ageList"
                        :key="age"
                        :disabled="age > (contentList[index].age_max || 65)"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col
                  class="line"
                  :span="1"
                  style="padding: 0 20px; text-align: center"
                  >-</el-col
                >
                <el-col :span="5">
                  <el-form-item prop="date2">
                    <el-select
                      v-model="contentList[index].age_max"
                      placeholder="请选择"
                      filterable
                      clearable
                      style="width: 100%"
                    >
                      <el-option
                        :label="
                          age == contentList[index].ageList[contentList[index].ageList.length - 1] ? age + '+' : age
                        "
                        :value="age"
                        v-for="age in contentList[index].ageList"
                        :key="age"
                        :disabled="age < (contentList[index].age_min || 21)"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-form-item>
              <placement
                :initData="initData"
                @getPlacemment="getPlacemment"
                :placement="placementMsg"
                v-show="smart_promotion_type != 'AUTOMATED_SHOPPING_ADS'"
              ></placement>
              <advanced-audience
                v-model="contentList[index].targeting_automation.advantage_audience"
              ></advanced-audience>
              <div
                class="shouzhong"
                v-show="smart_promotion_type != 'AUTOMATED_SHOPPING_ADS'"
              >
                <div class="name">受众设置：</div>
                <div class="shouzhongCon">
                  <div
                    class="list"
                    v-for="(people, ind) in contentList[index].flexible_spec"
                    :key="ind"
                  >
                    <p>
                      <span
                        >包含至少符合以下条件之一的人
                        <tips :type="ind ? 'second' : 'first'"></tips>
                        <recommend-audience
                          :sale-info="saleMsg || adSeriesInfo.sale"
                          :adset-id="adsetId"
                          @confirm="confirmAdd($event, index, ind)"
                        ></recommend-audience>
                        <el-input
                          style="width: 160px; margin-left: 10px"
                          size="small"
                          placeholder="输入商品id并回车"
                          v-model="saleId"
                          @keyup.enter.native="getSaleInfo"
                          @change="saleChange"
                        ></el-input>
                      </span>
                      <span class="tools">
                        <span
                          v-if="canSplitAudience(ind)"
                          @click="splitAudience(ind)"
                          >拆分受众</span
                        >
                        <span @click="deleAudience(ind)">删除</span>
                      </span>
                    </p>
                    <flexible-spec
                      ref="flexibleSpec"
                      :content-index="index"
                      :account-id="accountId + ''"
                      v-model="contentList[index].flexible_spec[ind]"
                      @move-to="moveToExclude"
                      move-to-tip="移动到排除受众"
                      :flexible-index="ind"
                      :invaild_insterest="statusData ? statusData.invaild_insterest : []"
                    ></flexible-spec>
                    <div
                      v-show="statusData && statusData.invaild_insterest && statusData.invaild_insterest.length"
                      style="color: red"
                    >
                      提示：存在无效的兴趣词，请删除后再提交
                    </div>
                  </div>
                  <!-- 排除受众 -->
                  <div v-if="hasExclude(index)">
                    <div
                      class="list"
                      v-for="(people, ind) in contentList[index].notPeople.slice(0, 1)"
                      :key="ind"
                    >
                      <p>
                        <span>排除符合以下条件的受众<tips type="third"></tips></span>
                        <span class="tools">
                          <span @click="delExcludeAudience">删除</span>
                        </span>
                      </p>
                      <flexible-spec
                        :account-id="accountId + ''"
                        v-model="contentList[index].notPeople[ind]"
                        move-to-tip="移动到缩小受众"
                        @move-to="moveToInclude"
                        :flexible-index="ind"
                      ></flexible-spec>
                    </div>
                  </div>
                  <div
                    class="chatGpt-box"
                    v-if="chatGptData.length > 0"
                  >
                    <span>CHATGPT推荐：</span>
                    <span
                      v-for="item in chatGptData"
                      :key="item"
                      >{{ item }}</span
                    >
                  </div>
                  <el-button
                    @click="addAudience('flexible_spec')"
                    size="mini"
                    type="text"
                    >缩小受众
                  </el-button>
                  <!-- {{contentList[index].excludeAudienceShow}} -->
                  <span
                    style="margin: 0 10px"
                    v-if="!hasExclude(index)"
                    >或</span
                  >
                  <el-button
                    @click="excludeAudience"
                    size="mini"
                    type="text"
                    v-if="!hasExclude(index)"
                  >
                    排除
                  </el-button>
                  <!-- <div>
                                        <el-switch v-model="contentList[index].targeting_optimization"
                                                active-value="expansion_all" inactive-value="none"></el-switch>
                                        <span>扩展细分定位，有机会提升广告表现时，向更多用户推广</span></div> -->
                </div>
              </div>
              <el-form-item
                label=""
                v-show="smart_promotion_type != 'AUTOMATED_SHOPPING_ADS'"
              >
                <el-button
                  @click="showMore = !showMore"
                  size="mini"
                  type="text"
                  >{{ showMore ? '收起' : '显示更多' }}
                </el-button>
              </el-form-item>
              <div
                class="shouzhong"
                v-if="showMore"
              >
                <div class="name">关系：</div>
                <div class="shouzhongCon">
                  <el-checkbox-group v-model="contentList[index].relation">
                    <div>
                      <p>
                        <el-checkbox label="key1">与以下内容建立关系网络的用户</el-checkbox>
                      </p>
                      <p v-if="contentList[index].relation.includes('key1')">
                        <el-select
                          v-model="contentList[index].connections"
                          placeholder="请选择主页"
                          multiple
                          clearable
                          style="width: 50%"
                        >
                          <el-option
                            :label="page.name"
                            :value="page.id"
                            v-for="page in accountPagesList"
                            :key="page.id"
                            :disabled="connectionsSelectDisabled(index, page)"
                          ></el-option>
                        </el-select>
                      </p>
                    </div>
                    <div>
                      <p>
                        <el-checkbox label="key2">与以下内容建立关系网络定位受众类似的人群</el-checkbox>
                      </p>
                      <p v-if="contentList[index].relation.includes('key2')">
                        <el-select
                          v-model="contentList[index].friends_of_connections"
                          placeholder="请选择主页"
                          multiple
                          clearable
                          style="width: 50%"
                        >
                          <el-option
                            :label="page.name"
                            :value="page.id"
                            v-for="page in accountPagesList"
                            :key="page.id"
                          ></el-option>
                        </el-select>
                      </p>
                    </div>
                    <div>
                      <p>
                        <el-checkbox label="key3">排除与以下内容建立关系网络的用户</el-checkbox>
                      </p>
                      <p v-if="contentList[index].relation.includes('key3')">
                        <el-select
                          v-model="contentList[index].excluded_connections"
                          placeholder="请选择主页"
                          multiple
                          clearable
                          style="width: 50%"
                        >
                          <el-option
                            :label="page.name"
                            :value="page.id"
                            v-for="page in accountPagesList"
                            :key="page.id"
                            :disabled="excludedConnectionsSelectDisabled(index, page)"
                          ></el-option>
                        </el-select>
                      </p>
                    </div>
                  </el-checkbox-group>
                </div>
              </div>
            </div>
          </el-form>
        </el-tab-pane>
        <el-tab-pane
          name="-1"
          v-if="isCustomSale"
        >
          <span slot="label"><i class="el-icon-circle-plus-outline"></i> 添加受众</span>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div
      class="audienceCover"
      v-if="audienceInfo"
    >
      <div
        class="freshCon"
        @click="freshCon = false"
        v-show="freshCon"
      >
        <p>预估覆盖受众</p>
        <div class="num">{{ usersStage.usersWithUnit(statusData.estimate_mau || statusData.estimate_dau || 0) }}</div>
        <p><i class="el-icon-caret-left"></i></p>
      </div>
      <audiencep-previews
        v-show="!freshCon"
        @close="close"
        :edit="true"
        :statusData="statusData"
        :content="JSON.stringify(contentList[activeIndex])"
        :accountId="accountId"
      ></audiencep-previews>
    </div>
    <div
      class="previews"
      v-if="!audienceInfo"
    >
      <audiencep-previews
        :count="1000"
        :statusData="statusData"
        :content="JSON.stringify(contentList[activeIndex])"
        :accountId="accountId"
      ></audiencep-previews>
    </div>
    <audience-list
      :audienceListShow="audienceListShow"
      @close="close"
      :myAudience="myAudience"
      @getAudienceContent="getAudienceContent"
    ></audience-list>
  </div>
</template>
<script>
import audiencepPreviews from './audiencePreviews';
import audienceList from './audienceList';
import tips from './targeting/tips';
import regionGroup from './regionGroup.vue';
import placement from './placement/placement';
import advancedAudience from './advancedAudience.vue';
import {
  savedContents,
  historyInput,
  targetingSearch,
  accountPages,
  deliveryEstimateAndSentence,
  promoteableSale,
} from '@/api/creatAd.js';
import { createAd, findSaleByAdSetId } from '@/api/audiences.js';
import FlexibleSpec from './targeting/FlexibleSpec';
import recommendAudience from './recommendAudience';
let imgLot = require('./targeting/targeting-lot.svg');
let imgLow = require('./targeting/targeting-low.svg');
let imgMid = require('./targeting/targeting-mid.svg');
function UsersStage(icon, color) {
  this.icon = icon;
  this.color = color;
  this.usersWithUnit = function (users) {
    if (users < 10e3) return users;
    if (users < 10e7) return (users / 10e3).toFixed(0) + '万';
    return (users / 10e7).toFixed(0) + '亿';
  };
}

const stages = [
  new UsersStage(imgLow, 'rgb(245, 89, 73)'),
  new UsersStage(imgMid, 'rgb(0, 183, 148)'),
  new UsersStage(imgLot, 'rgb(239, 131, 0)'),
];
export default {
  // type 表示是不是草稿 DRAFT
  props: [
    'accountId',
    'initData',
    'adSeriesInfo',
    'audienceInfo',
    'adsetId',
    'rowThirdNum',
    'smart_promotion_type',
    'type',
  ],
  // props:{
  //     accountId:{
  //         type:Number,
  //         default:0
  //     },
  //     initData:{
  //         type:Object,
  //         default: () => {}
  //     },
  //     adSeriesInfo:{
  //         type:Object,
  //         default: () => {}
  //     }
  // },
  data() {
    return {
      activeTab: '1',
      activeIndex: 0,
      // editableTabs: [{}],
      editable: false,
      contentList: [this.contentListDefault()],
      peopleGroup: [
        { name: '该地区内的所有用户', id: 'all' },
        { name: '居住在该地区的用户', id: 'home' },
        {
          name: '最近位于该地区的用户',
          id: 'recent',
        },
        { name: '到该地区旅游的用户', id: 'travel_in' },
      ],
      rules: {},
      audienceList: [
        { name: '受众1', id: 1 },
        { name: '受众2', id: 2 },
        { name: '受众3', id: 3 },
      ],
      typeList: [
        { key: 'interestsuggestion', name: '荐词' },
        { key: 'interests', name: '兴趣' },
        {
          key: 'behaviors',
          name: '行为',
        },
        { key: 'custom_audience', name: '自定义受众' },
        {
          key: 'education_schools',
          name: '学校',
        },
        { key: 'education_statuses', name: '学历' },
        { key: 'family_statuses', name: '家庭状况' },
        {
          key: 'income',
          name: '收入',
        },
        { key: 'life_events', name: '生活纪事' },
        { key: 'relationship_statuses', name: '感情状况' },
        {
          key: 'industries',
          name: '行业',
        },
      ],
      countryList: [
        { label: '常用地区', options: [] },
        { label: ' ', options: [] },
      ],
      showMore: false,
      audienceListShow: false,
      targeting: {},
      ageList: [],
      myAudience: [], //默认账户保存受众列表
      adviseList: {}, //分类下的受众列表
      accountPagesList: [],
      statusData: {},
      targetingList: [],
      freshCon: true,
      edit: false,
      saleId: '',
      saleMsg: null,
      chatGptData: [],
      invaild_insterest: [],
    };
  },
  components: {
    FlexibleSpec,
    audiencepPreviews,
    audienceList,
    tips,
    regionGroup,
    recommendAudience,
    placement,
    advancedAudience,
  },
  filters: {
    audienceTabName(v, index) {
      let interest = [];
      if (!v) {
        return '';
      }
      v.map((item) => {
        // console.log(item);
        interest = interest.concat(item.interests ? item.interests : []);
        // if(item){
        //     interest = interest.concat(item.interests?item.interests:[])
        // }
      });
      // console.log(interest);
      return interest.length
        ? interest
            .map((c) => (c.name ? c.name : ''))
            .slice(0, 3)
            .join(',') + (interest.length > 3 ? '...' : '')
        : '受众' + (index / 1 + 1);
    },
  },
  computed: {
    isCustomSale() {
      return this.adSeriesInfo.smart_promotion_type !== 'AUTOMATED_SHOPPING_ADS';
    },
    editableTabs: {
      get() {
        return this.contentList.map((v) => v.name);
      },
      set() {},
    },
    selectGeoLocations() {
      let countryList = JSON.parse(JSON.stringify(this.countryList));
      let geoV = this.contentList[this.activeIndex].excluded_geo_locations || [];
      let regionsList = this.contentList[this.activeIndex].geo_locations_region || [];
      let cityList = this.contentList[this.activeIndex].geo_locations_city || [];
      for (var i = 0; i < countryList.length; i++) {
        // if(!countryList[i].label){
        countryList[i].options.map(
          (o) =>
            (o.disabled =
              geoV.includes(o.key) ||
              regionsList.some((v) => v.country_code == o.key) ||
              cityList.some((m) => m.country_code == o.key)),
        );
        // }
      }
      return countryList;
    },
    selectExcludeGeoLocations() {
      let countryList = this.countryList;
      let geoV = this.contentList[this.activeIndex].geo_locations || [];
      // let regionsList =  this.contentList[this.activeIndex].geo_locations_region || [];
      // let cityList =  this.contentList[this.activeIndex].geo_locations_city || [];
      for (var i = 0; i < countryList.length; i++) {
        countryList[i].options.map((o) => (o.exdisabled = geoV.includes(o.key)));
      }

      // console.log(countryList);
      return countryList;
    },
    regionsList() {
      let regions = this.initData.targeting ? this.initData.targeting.TargetingGeoRegions : [];
      let geoV = this.contentList[this.activeIndex].geo_locations || [];
      let excluded_geo_region = this.contentList[this.activeIndex].excluded_geo_locations_regions || [];
      let excludedGeo = this.contentList[this.activeIndex].excluded_geo_locations || [];
      regions.map((v) => (v.disabled = excludedGeo.includes(v.country.key)));
      regions.map((v) => {
        v.regions.map((o) => {
          o.disabled = excluded_geo_region.some((k) => k.key == o.key);
        });
      });
      // if(regions.filter(v=>geoV.includes(v.country_code)).length == 0){
      //     this.contentList[this.activeIndex].geo_locations_region = []
      // }
      return regions;
      // return regions.filter(v=>geoV.includes(v.country_code))
    },
    excludeRegionsList() {
      let regions = this.initData.targeting ? this.initData.targeting.TargetingGeoRegions : [];
      let geoV = this.contentList[this.activeIndex].geo_locations_region || [];
      let excludedGeo = this.contentList[this.activeIndex].excluded_geo_locations || [];
      regions.map((v) => (v.disabled = excludedGeo.includes(v.country.key)));
      regions.map((v) => {
        v.regions.map((o) => {
          o.disabled = geoV.some((k) => k.key == o.key);
        });
      });
      // if(regions.filter(v=>geoV.includes(v.country_code)).length == 0){
      //     this.contentList[this.activeIndex].geo_locations_region = []
      // }
      return regions;
      // return regions.filter(v=>geoV.includes(v.country_code))
    },
    cityList() {
      let citys = this.initData.targeting ? this.initData.targeting.TargetingGeoCitys : [];
      let geoV = this.contentList[this.activeIndex].geo_locations || [];
      // console.log(geoV);
      citys.map((v) => (v.disabled = geoV.includes(v.country.key)));
      return citys;
    },
    excludedCityList() {
      let citys = this.initData.targeting ? this.initData.targeting.TargetingGeoCitys : [];
      let excludedGeo = this.contentList[this.activeIndex].excluded_geo_locations || [];
      let excludedRegions = this.contentList[this.activeIndex].excluded_geo_locations_regions || [];
      citys.map((v) => (v.disabled = excludedGeo.includes(v.country.key)));
      citys.map((v) => {
        v.cities.map((k) => (k.disabled = excludedRegions.map((v) => v.key).includes(k.region_id)));
      });
      return citys;
    },
    excludedSubCityList() {
      let citys = this.initData.targeting ? this.initData.targeting.TargetingGeoSubCities : [];
      let excludedGeo = this.contentList[this.activeIndex].excluded_geo_locations || [];
      let excludedRegions = this.contentList[this.activeIndex].excluded_geo_locations_regions || [];
      citys.map((v) => (v.disabled = excludedGeo.includes(v.country.key)));
      citys.map((v) => {
        v.cities.map((k) => (k.disabled = excludedRegions.map((v) => v.key).includes(k.region_id)));
      });
      return citys;
    },
    excludedPlacesList() {
      let places = this.initData.targeting ? this.initData.targeting.TargetingGeoPlaces : [];
      let excludedGeo = this.contentList[this.activeIndex].excluded_geo_locations || [];
      let excludedRegions = this.contentList[this.activeIndex].excluded_geo_locations_regions || [];
      let excludedCities = this.contentList[this.activeIndex].excluded_geo_locations_cities || [];
      places.map((v) => (v.disabled = excludedGeo.includes(v.country.key)));
      places.map((v) => {
        v.places.map(
          (k) =>
            (k.disabled =
              excludedRegions.map((v) => v.key).includes(k.region_id) ||
              excludedCities.map((v) => v.key).includes(k.primary_city_id)),
        );
      });
      console.log(places);
      return places;
    },
    usersStage() {
      let c = this.users;
      if (c) {
        if (c < 2e4) return stages[0];
        if (c < 8e6) return stages[1];
      }
      return stages[2];
    },
    placementMsg() {
      let audience = JSON.parse(JSON.stringify(this.contentList[this.activeIndex]));
      let obj = {
        device_platforms: audience.device_platforms || [],
        user_os: audience.user_os || ['All'],
        user_device: audience.user_device || [],
        facebook_positions: audience.facebook_positions || [],
        instagram_positions: audience.instagram_positions || [],
        audience_network_positions: audience.audience_network_positions || [],
        messenger_positions: audience.messenger_positions || [],
      };
      return obj;
    },
    currentAddSaleId() {
      return this.$store.state.num.currentAddSaleId;
    },
  },
  methods: {
    // 获取商品信息
    getSaleInfo() {
      if (this.saleId > 2147483647 || !this.testNum(this.saleId)) {
        this.$message({
          type: 'warning',
          message: '请输入正确的商品ID',
        });
        this.saleMsg = null;
        return false;
      }
      promoteableSale({ sale_id: this.saleId }).then((res) => {
        // console.log(res);
        if (res.code == 0) {
          if (!res.data.valid) {
            this.$message({
              type: 'warning',
              message: res.data.error,
            });
            this.saleMsg = null;
            return false;
          } else {
            this.$message({
              type: 'success',
              message: '获取商品成功可以获取推荐受众',
            });
          }
          this.saleMsg = res.data.sale;
          console.log(this.saleMsg, '------------salemsg-----------');
        }
      });
    },
    testNum(num) {
      if (num == '') {
        return false;
      }
      if (!/(^[1-9]\d*$)/.test(num)) {
        return false;
      } else {
        return true;
      }
    },
    saleChange(e) {
      if (isNaN(e)) {
        this.saleMsg = null;
      }
      if (!e) {
        this.saleMsg = null;
      }
    },
    connectionsSelectDisabled(index, a) {
      if (a && this.contentList[index].excluded_connections) {
        return this.contentList[index].excluded_connections.some((b) => b === a.id);
      }
      return false;
    },
    excludedConnectionsSelectDisabled(index, a) {
      if (a && this.contentList[index].connections) {
        // console.log('this.contentList[index].connections.some(b=>b.id===a.id);',this.contentList[index].connections,a)
        return this.contentList[index].connections.some((b) => b === a.id);
      }
      return false;
    },
    contentListDefault() {
      console.log(this.adSeriesInfo);
      return {
        audienceName: '请选择',
        peopleGroup: '该地区内的所有用户',
        genders: 'all',
        excludeCountryShow: false,
        excludeAudienceShow: false,
        relation: [],
        flexible_spec: [],
        notPeople: [],
        age_min: 14,
        age_max: 64,
        geo_locations: [],
        geo_locations_region: [],
        geo_locations_city: [],
        targeting_optimization: 'expansion_all',
        ageList: [],
        targeting_automation: {
          advantage_audience: 0,
        },
      };
      // this.adSeriesInfo?.site?.countyCode
    },
    emptyFlexible() {
      return {
        interests: [],
        behaviors: [],
        custom_audience: [],
        education_schools: [],
        education_statuses: [],
        family_statuses: [],
        income: [],
        industries: [],
        life_events: [],
        relationship_statuses: [],
        adworkemployer: [],
        adworkposition: [],
      };
    },
    hasExclude(index) {
      if (
        this.contentList[index] &&
        this.contentList[index].notPeople &&
        this.contentList[index].notPeople.length > 0 &&
        this.contentList[index].notPeople[0]
      ) {
        return true;
      }
      return false;
    },
    moveToExclude(type, flexibleObj, flexibleIndex) {
      // this.$set(this.contentList[this.activeIndex].flexible_spec[flexibleIndex], type, []);
      let v = flexibleObj;
      // console.log("moveToExclude", type, flexibleObj, flexibleIndex)
      if (this.contentList[this.activeIndex].notPeople.length === 0) {
        let obj = this.emptyFlexible();
        obj[type] = v;
        this.$set(this.contentList[this.activeIndex].notPeople, 0, obj);
      } else {
        this.$set(this.contentList[this.activeIndex].notPeople[0], type, v);
      }
    },
    moveToInclude(type, flexibleObj, flexibleIndex) {
      let v = flexibleObj;
      let spec = this.contentList[this.activeIndex].flexible_spec;
      // console.log("moveToInclude", type, v, flexibleIndex, spec)
      if (spec.length === 0) {
        let obj = this.emptyFlexible();
        obj[type] = v;
        spec.push(obj);
        this.$set(this.contentList[this.activeIndex], 'flexible_spec', spec);
      } else {
        let i = spec.length - 1;
        this.$set(this.contentList[this.activeIndex].flexible_spec[i], type, v);
      }
    },
    flexibleInclude(v) {
      // console.log("flexibleInclude:", v)
    },
    flexibleExclude(v) {
      // console.log("flexibleExclude:", v)
    },
    // close
    close(v) {
      this[v] = false;
      if (v == 'freshCon') {
        this.freshCon = true;
      }
    },
    selectDefaultCountry(index) {
      // 自动填充
      /**
       * regionsList 省，cityList 市
       * selectExcludeGeoLocations  排除地区
       * excludeRegionsList 排除省份
       * excludedCityList 排除城市
       * excludedSubCityList 排除下级城市
       * excludedPlacesList 排除城镇
       * contentList[index].geo_locations_region 省份，contentList[index].geo_locations_city 城市，
       * contentList[index].excluded_geo_locations 排除地区， contentList[index].excluded_geo_locations_regions 排除省份，
       * contentList[index].excluded_geo_locations_cities 排除城市， contentList[index].excluded_geo_locations_subcities 排除下级城市，
       * contentList[index].excluded_geo_locations_places 排除城镇， contentList[index].excluded_geo_locations_place_radius 公里数
       */
      if (this.adSeriesInfo?.countryAutofill) {
        let countryAutofill = this.adSeriesInfo.countryAutofill;
        let regions = countryAutofill.regions; // 省
        let cities = countryAutofill.cities; // 市
        let excludedCities = countryAutofill.excludedCities; //排除 市
        let excludedRegions = countryAutofill.excludedRegions; //排除 省
        let excludedSubcities = countryAutofill.excludedSubcities; // 排除下级城市
        let excludedPlaces = countryAutofill.excludedPlaces; //排除 城镇
        let excluded_geo_locations = countryAutofill.excludedCountries; // 排除地区
        /**
         * 1 数组扁平化
         * 2 过滤出当前 需要自动填充的数据
         */
        if (regions) {
          // 省
          this.$set(this.contentList[index], 'geo_locations', []);
          this.filterHandle(this.regionsList, regions, 'regions', 'geo_locations_region', index);
        }
        if (cities) {
          this.$set(this.contentList[index], 'geo_locations', []);
          this.filterHandle(this.cityList, cities, 'cities', 'geo_locations_city', index);
        }
        if (excluded_geo_locations) {
          // 排除地区
          this.$set(this.contentList[index], 'excluded_geo_locations', excluded_geo_locations.split(','));
        }

        if (excludedRegions) {
          // 排除省份
          this.filterHandle(
            this.excludeRegionsList,
            excludedRegions,
            'regions',
            'excluded_geo_locations_regions',
            index,
          );
        }
        if (excludedCities) {
          // 排除市
          this.filterHandle(this.excludedCityList, excludedCities, 'cities', 'excluded_geo_locations_cities', index);
        }
        if (excludedSubcities) {
          // 下级市
          this.filterHandle(
            this.excludedSubCityList,
            excludedSubcities,
            'cities',
            'excluded_geo_locations_subcities',
            index,
          );
        }
        if (excludedPlaces) {
          // 排除城镇
          this.filterHandle(this.excludedPlacesList, excludedPlaces, 'places', 'excluded_geo_locations_places', index);
          this.contentList[index].excluded_geo_locations_place_radius = 2;
        }
        if (excludedRegions || excludedCities || excludedSubcities || excludedPlaces) {
          this.contentList[index].excludeCountryShow = true;
        }
        /**
         * 地区 与 省、市 互斥
         */
        if (!regions && !cities) {
          this.$set(this.contentList[index], 'geo_locations', [this.adSeriesInfo?.site?.countryCode]);
        }
      } else {
        this.$set(this.contentList[index], 'geo_locations', []); //如果上次已经填写过，则清空重新写值
        this.filterHandle(this.regionsList, [], 'regions', 'geo_locations_region', index);
        this.filterHandle(this.cityList, [], 'cities', 'geo_locations_city', index);
        this.$set(this.contentList[index], 'excluded_geo_locations', []);
        this.filterHandle(this.excludeRegionsList, [], 'regions', 'excluded_geo_locations_regions', index);
        this.filterHandle(this.excludedCityList, [], 'cities', 'excluded_geo_locations_cities', index);
        this.filterHandle(this.excludedSubCityList, [], 'cities', 'excluded_geo_locations_subcities', index);
        this.contentList[index].excludeCountryShow = false;
        this.setAgeList();
        this.$set(this.contentList[index], 'geo_locations', [this.adSeriesInfo?.site?.countryCode]);
      }
      let locationsAges = this.contentList[index].geo_locations;
      if (locationsAges.includes('TH'))
        this.$set(
          this.contentList[index],
          'age_min',
          this.contentList[index].age_min >= 20 ? this.contentList[index].age_min : 20,
        );

      if (locationsAges.includes('ID'))
        this.$set(
          this.contentList[index],
          'age_min',
          this.contentList[index].age_min >= 21 ? this.contentList[index].age_min : 21,
        );
    },
    /**
     * 1 数组扁平化
     * 2 过滤出当前 需要自动填充的数据
     * list: regionsList 省 | cityList 市 | selectExcludeGeoLocations  排除地区 | excludeRegionsList 排除省份 | excludedCityList 排除城市 | excludedSubCityList 排除下级城市 | excludedPlacesList 排除城镇   当前所需列表
     * data: regions | cities | excludedRegions | excludedCities | excludedSubcities | excludedPlaces 需要填充的数据
     * key: regions | cities | places 便利的key
     * listkey: 需要赋值的对象
     * index: contentList 当前需要赋值的 index
     */
    filterHandle(list, data, key, listkey, index) {
      let filterData = list
        .map((v) => v[key])
        .flat()
        .filter((item) => data.indexOf(item.key) > -1);
      this.$set(this.contentList[index], listkey, filterData);
    },
    tabsLeave() {
      if (this.statusData.invaild_insterest && this.statusData.invaild_insterest.length > 0) {
        this.$notify.error('存在无效的兴趣词，请删除后再操作');
        return false;
      }
      return true;
    },
    // 点击切换选项卡
    handleClick(tab) {
      console.log(tab.index);
      if (this.statusData.invaild_insterest && this.statusData.invaild_insterest.length > 0) {
        return false;
      }
      this.activeIndex = tab.index;
      // 添加受众
      if (tab.name == '-1') {
        let obj = { content: `受众${this.editableTabs.length + 1 + ''}的内容` };
        this.editableTabs.push({});
        this.contentList.push(this.contentListDefault());
        this.addAudience('flexible_spec');
        // this.contentList[this.activeIndex].age_min = this.initData.targeting.TargetingAgeRange.minDefault;
        // this.contentList[this.activeIndex].age_max = this.initData.targeting.TargetingAgeRange.max;
        this.setAgeList();
        this.$emit('audienceMsg', this.targetingList);
        this.activeTab = this.editableTabs.length + '';
      }
      this.refreshData();
      // console.log(this.activeIndex);
    },
    // 设置年龄选择范围
    setAgeList() {
      let ageMinList = [];
      if (this.contentList[this.activeIndex].geo_locations && this.contentList[this.activeIndex].geo_locations.length) {
        this.contentList[this.activeIndex].geo_locations.map((v) => {
          if (this.initData.targeting.TargetingAgeRange.specialAgeRange[v]) {
            ageMinList.push(this.initData.targeting.TargetingAgeRange.specialAgeRange[v]);
          }
        });
      }
      let min = ageMinList.length ? Math.max(...ageMinList) : this.initData.targeting.TargetingAgeRange.min;
      var list = [];
      // console.log(this.initData);
      for (var i = min; i <= this.initData.targeting.TargetingAgeRange.max; i++) {
        list.push(i);
      }
      // list.push(this.initData.targeting.TargetingAgeRange.max)
      // console.log(list);
      // 年龄范围
      let minDefault = ageMinList.length
        ? Math.max(...ageMinList)
        : this.initData.targeting.TargetingAgeRange.minDefault;
      this.contentList[this.activeIndex].age_min = minDefault;
      this.contentList[this.activeIndex].age_max = this.initData.targeting.TargetingAgeRange.max;
      this.contentList[this.activeIndex].ageList = list;
    },
    // 设置受众初始化信息
    setAudienceInfo() {
      this.setAgeList();
      // 国家
      this.$set(this.countryList, 1, { label: '', options: this.initData.targeting.TargetingCountry });
      // console.log(this.countryList);
    },
    // 克隆受众/删除受众
    handleTabsEdit(targetName, action) {
      // console.log(targetName);
      // 克隆受众

      let index;
      if (action === 'add') {
        index = this.activeTab / 1 - 1;
        // console.log(this.contentList[index]);
        let content = JSON.parse(JSON.stringify(this.contentList[index]));
        let obj = { content: content };
        this.editableTabs.push({});
        this.contentList.push(content);
        this.activeTab = this.editableTabs.length + '';
      }
      // 删除受众
      if (action === 'remove') {
        if (this.contentList.length == 1) {
          this.$message({
            type: 'warning',
            message: '至少保留一个受众信息',
          });
          return false;
        }
        index = targetName / 1 - 1;
        // console.log(index);
        this.editableTabs.splice(index, 1);
        this.contentList.splice(index, 1);
        this.targetingList.splice(index, 1);
        this.$emit('audienceMsg', this.targetingList);
        // console.log(this.editableTabs);
        this.activeTab = index + '';
        // console.log(this.activeIndex);
      }
      if (targetName == 1) {
        // console.log('aaaaaaaaa');
        this.activeIndex = 0;
        this.activeTab = '1';
      } else {
        this.activeIndex = this.activeTab / 1 - 1;
      }
      // console.log( this.activeTab,this.activeIndex);
    },
    // 点击选择已存的受众
    handleCommand(command) {
      // console.log(command);
      if (command == -1) {
        this.audienceListShow = true;
        return;
      }
      let audience = this.myAudience.filter((v) => v.id == command);
      this.contentList[this.activeIndex] = JSON.parse(audience[0].content);
      let list = JSON.parse(audience[0].content).audienceListAll;
      if (list && list.length) {
        list.map((v) => {
          if (!this.adviseList[v.type.name]) {
            targetingSearch({
              accountId: this.accountId,
              targetingType: v.type.name,
              query: '',
            }).then((res) => {
              this.adviseList[v.type.name] = res.data;
            });
          }
        });
      }
      this.$set(this.contentList, this.activeIndex, JSON.parse(audience[0].content));
      this.contentList[this.activeIndex].audienceName = audience[0].name;
      // console.log(this.contentList);
    },
    // 选择地区的受众人群
    handlePeopleGroup(peoples) {
      let people = this.peopleGroup.filter((v) => v.id == peoples);
      this.contentList[this.activeIndex].peopleGroup = people[0].name;
      this.contentList[this.activeIndex].location_types = people[0].id;
    },
    // 点击排除地区
    excludeCountry() {
      this.contentList[this.activeIndex].excludeCountryShow = !this.contentList[this.activeIndex].excludeCountryShow;
    },
    // 点击缩小受众
    addAudience(type) {
      this.contentList[this.activeIndex][type].push(this.emptyFlexible());
      let i = this.contentList[this.activeIndex][type].length;
      this.$set(this.contentList[this.activeIndex][type], i - 1, this.emptyFlexible());
    },

    // 拆分受众
    canSplitAudience(index) {
      if (this.contentList[this.activeIndex].flexible_spec.length !== 1) return false;
      let audience = this.contentList[this.activeIndex].flexible_spec[index];
      if (!audience) return false;
      let types = {};
      let n = 0;
      for (let k in audience) {
        let kList = audience[k];
        for (let i in kList) {
          let item = kList[i];
          if (!types[item.type]) {
            types[item.type] = true;
            n++;
          }
        }
      }
      return n > 1;
    },
    splitAudience(index) {
      let audience = this.contentList[this.activeIndex].flexible_spec[0];
      // console.log(audience);
      let list = [];
      for (let key in audience) {
        let v = audience[key];
        let item = {};
        item[key] = v;
        if (v && v.length > 0) {
          list.push(item);
        }
      }
      // console.log('list',list);
      this.contentList[this.activeIndex].flexible_spec = list;
    },
    // 删除受众
    deleAudience(index) {
      if (this.contentList[this.activeIndex].flexible_spec.length > 1) {
        this.contentList[this.activeIndex].flexible_spec.splice(index, 1);
      } else {
        this.$message({
          type: 'warning',
          message: '请至少保留一个受众',
        });
      }
    },
    // 点击排除受众
    excludeAudience() {
      this.contentList[this.activeIndex].excludeAudienceShow = true;
      this.addAudience('notPeople');
    },
    // 删除排除受众
    delExcludeAudience() {
      this.contentList[this.activeIndex].excludeAudienceShow = false;
      this.contentList[this.activeIndex].notPeople = [];
    },

    // 获取受众信息
    getAudienceContent() {
      savedContents({ accountId: this.accountId, savedType: 'TARGETING' }, this.rowThirdNum).then((res) => {
        // console.log(res);
        this.myAudience = res.data;
      });
    },
    // 获取地区的历史记录
    getCountryHistory() {
      historyInput({ accountId: this.accountId, type: 'COUNTRY' }).then((res) => {
        let list = res.data;
        // console.log(list);
        let contryList = this.initData.targeting ? this.initData.targeting.TargetingCountry : [];
        // console.log(contryList);
        if (list && list.length > 0) {
          let hisInput = [];
          list.map((item) => {
            hisInput = hisInput.concat(contryList.filter((v) => v.key == item));
          });
          this.$set(this.countryList, 0, { label: '常用地区', options: hisInput });
        }
      });
    },
    // 选择地区，根据地区选择语言
    beforeSelectCountry(b) {
      if (b) {
        this.contentList[this.activeIndex].geo_locations_old_val = this.contentList[this.activeIndex].geo_locations;
      }
    },
    selectCountry(v) {
      // console.log(v);
      let oldV = this.contentList[this.activeIndex].geo_locations_old_val || [];
      let newAddV = v.filter((i) => {
        return !oldV.includes(i);
      });
      // 根据国家判断年龄区间
      this.setAgeList();
      // let list = this.contentList[this.activeIndex].locales || [];
      // newAddV.map(item => {
      //     this.initData.targeting.TargetingCountry.map(i => {
      //         if (i.key === item) {
      //             // console.log(i.defaultLocal.key);
      //             if (!list.includes(i.defaultLocal.key)) {
      //                 list.push(i.defaultLocal.key)
      //             }
      //             // return i.defaultLocal.key
      //         }
      //     })
      // })
      // this.contentList[this.activeIndex].locales = list;
      // console.log(list);
    },
    // 设置省份
    setRegions(v) {
      this.contentList[this.activeIndex].geo_locations_region = JSON.parse(v);
      this.$set(this.contentList, this.activeIndex, this.contentList[this.activeIndex]);
    },
    setCity(v) {
      this.contentList[this.activeIndex].geo_locations_city = JSON.parse(v);
      this.$set(this.contentList, this.activeIndex, this.contentList[this.activeIndex]);
    },
    setExcludeRegion(v) {
      this.contentList[this.activeIndex].excluded_geo_locations_regions = JSON.parse(v);
      this.$set(this.contentList, this.activeIndex, this.contentList[this.activeIndex]);
    },
    setExcludeCity(v) {
      this.contentList[this.activeIndex].excluded_geo_locations_cities = JSON.parse(v);
      this.$set(this.contentList, this.activeIndex, this.contentList[this.activeIndex]);
    },
    // 把所有选择的缩小受众放到一个数组里，排除受众的时候不可选
    allAudienceList(index, val) {
      let audienceList = JSON.parse(JSON.stringify(this.contentList));
      // let audienceListAll = audienceList[index].flexible_spec;
      let audienceListAll = this.contentList[index].audienceListAll || [];
      // console.log(this.contentList[index].audienceListAll);
      let newList = [...audienceListAll, ...val];
      // console.log('new');
      // console.log(this.unique(newList));
      this.contentList[index].audienceListAll = this.unique(newList);
    },
    // 数组对象合并去重
    unique(arr) {
      const res = new Map();
      return arr.filter((arr) => !res.has(arr.id) && res.set(arr.id, 1));
    },
    // 获取受众分类
    getTargetList(index, val, type) {
      if (type == 'notPeople') {
        index = 0;
      }
      targetingSearch({ accountId: this.accountId, targetingType: val.key, query: '' }).then((res) => {
        // console.log(res);
        // console.log(index);
        // this.contentList[this.activeIndex].flexible_spec[index].targetList = res.data;
        this.$set(this.contentList[this.activeIndex][type][index], 'targetList', res.data);
        this.adviseList[val.key] = res.data;
      });
    },
    // 编辑的时候获取下拉列表
    getTargetListDefault(index, val, type) {
      return targetingSearch({ accountId: this.accountId, targetingType: val.key, query: '' }).then((res) => {
        // console.log(res);
        // console.log(index);
        // this.contentList[this.activeIndex].flexible_spec[index].targetList = res.data;
        // this.$set(this.audienceInfo[type][index],'targetList',res.data);
        this.adviseList[val.key] = res.data;
      });
    },
    //获取账户主页
    getAcoountPages() {
      accountPages({ accountId: this.accountId }, this.rowThirdNum).then((res) => {
        // console.log(res);
        this.accountPagesList = res.data;
      });
    },
    // 把受众数据转化成后台需要的数据
    initAudienceData(oldAudience) {
      let audience_spec = [];
      if (oldAudience && oldAudience.length) {
        oldAudience.map((spec) => {
          let obj = {};
          for (let k in spec) {
            let val = spec[k];
            if (val && val.length) {
              val.map((find) => {
                // console.log(find);
                let item = {};
                item.id = find.id;
                item.name = find.name;
                item.type = find.type;
                if (!obj[item.type]) {
                  obj[item.type] = [];
                }
                if (!obj[item.type].some((v) => v.type === item.type && v.id === item.id)) {
                  obj[item.type].push(item);
                }
              });
            }
          }
          audience_spec.push(obj);
        });
      }
      return audience_spec;
    },
    // 获取受众可视化信息
    getChartData(data) {
      // this.$showLoading()
      data.invaild_insterest = this.statusData?.invaild_insterest || [];
      deliveryEstimateAndSentence(data, this.rowThirdNum)
        .then((res) => {
          if (res.code == 0) {
            this.statusData = res.data;
          } else {
            this.statusData = {};
          }
        })
        .finally(() => {
          // this.$hideLoading()
        });
    },
    // 接收版位信息
    getPlacemment(val) {
      // this.contentList[this.activeIndex] = {...this.contentList[this.activeIndex],...val};
      for (let key in val) {
        this.$set(this.contentList[this.activeIndex], key, val[key]);
      }
    },
    setAudiencList() {
      let list = [];
      let audienceData = JSON.parse(JSON.stringify(this.contentList));
      console.log(audienceData);
      audienceData.map((v, index) => {
        let oldAudience = audienceData[index].flexible_spec;
        let newAudience = this.initAudienceData(oldAudience);
        // console.log(newAudience);
        let oldExculdeAudience = audienceData[index].notPeople;
        let newExculdeAudience = this.initAudienceData(oldExculdeAudience);
        // console.log(newExculdeAudience);
        let params = JSON.parse(JSON.stringify(audienceData[index]));
        // 处理性别
        if (params.genders == 'all') {
          params.genders = ['F', 'M'];
        } else {
          params.genders = [params.genders];
        }
        delete params.relation;
        delete params.peopleGroup;
        delete params.notPeople;
        delete params.flexible_spec;
        delete params.excludeCountryShow;
        delete params.excludeAudienceShow;
        delete params.audienceName;
        delete params.audienceListAll;
        params.flexible_spec = newAudience;
        params.exclusions = newExculdeAudience[0];
        params.location_types = params.location_types || 'all';
        params.locales = params.locales || [];
        // console.log(params);
        params.connections = params.connections
          ? params.connections.map((v) => {
              return { id: v };
            })
          : [];
        params.excluded_connections = params.excluded_connections
          ? params.excluded_connections.map((v) => {
              return { id: v };
            })
          : [];
        params.friends_of_connections = params.friends_of_connections
          ? params.friends_of_connections.map((v) => {
              return { id: v };
            })
          : [];
        if (!params.targeting_automation) {
          params.targeting_automation = {
            advantage_audience: 0,
          };
        }
        list.push(params);
      });
      console.log(list);
      this.$emit('audienceMsg', list);
    },
    // 监听受众信息变化，获取可视化信息
    refreshData() {
      let audienceData = JSON.parse(JSON.stringify(this.contentList));
      // console.log(audienceData);
      let oldAudience = audienceData[this.activeIndex].flexible_spec;
      let newAudience = this.initAudienceData(oldAudience);
      // console.log(newAudience);
      let oldExculdeAudience = audienceData[this.activeIndex].notPeople;
      let newExculdeAudience = this.initAudienceData(oldExculdeAudience);
      // console.log(newExculdeAudience);
      let params = JSON.parse(JSON.stringify(audienceData[this.activeIndex]));
      // 处理性别
      if (params.genders == 'all') {
        params.genders = ['F', 'M'];
      } else {
        params.genders = [params.genders];
      }
      delete params.relation;
      delete params.peopleGroup;
      delete params.notPeople;
      delete params.flexible_spec;
      delete params.excludeCountryShow;
      delete params.excludeAudienceShow;
      delete params.audienceName;
      delete params.audienceListAll;
      params.flexible_spec = newAudience;
      params.exclusions = newExculdeAudience[0];
      params.location_types = params.location_types || 'all';
      params.locales = params.locales || [];
      // console.log(params);
      params.connections = params.connections
        ? params.connections.map((v) => {
            return { id: v };
          })
        : [];
      params.excluded_connections = params.excluded_connections
        ? params.excluded_connections.map((v) => {
            return { id: v };
          })
        : [];
      params.friends_of_connections = params.friends_of_connections
        ? params.friends_of_connections.map((v) => {
            return { id: v };
          })
        : [];
      this.targetingList[this.activeIndex] = { ...params };
      // console.log(this.adSeriesInfo);
      let data = { ...this.adSeriesInfo, accountId: this.accountId, targeting: params };
      delete data.siteUrl;
      data.targeting.geo_locations_region = data.targeting.geo_locations_region
        ? JSON.parse(JSON.stringify(data.targeting.geo_locations_region)).map((v) => v.key)
        : [];
      data.targeting.geo_locations_city = data.targeting.geo_locations_city
        ? JSON.parse(JSON.stringify(data.targeting.geo_locations_city)).map((v) => v.key)
        : [];
      data.targeting.excluded_geo_locations_regions = data.targeting.excluded_geo_locations_regions
        ? JSON.parse(JSON.stringify(data.targeting.excluded_geo_locations_regions)).map((v) => v.key)
        : [];
      data.targeting.excluded_geo_locations_place_radius = data.targeting.excluded_geo_locations_place_radius
        ? data.targeting.excluded_geo_locations_place_radius / 1
        : null;
      // console.log(data.targeting.geo_locations_region);
      if (
        data.targeting.excluded_geo_locations_places &&
        data.targeting.excluded_geo_locations_places.length &&
        !data.targeting.excluded_geo_locations_place_radius
      ) {
        this.statusData = {};
        return false;
      }
      if (
        (data.targeting.geo_locations && data.targeting.geo_locations.length) ||
        (data.targeting.geo_locations_region && data.targeting.geo_locations_region.length) ||
        (data.targeting.geo_locations_city && data.targeting.geo_locations_city.length)
      ) {
        this.getChartData(data);
      } else {
        this.statusData = {};
      }
    },
    confirmAdd(e, index, ind) {
      e.map((v) => {
        v.typeDesc = v.type.desc;
        v.type = v.type.key;
      });
      const res = this.contentList[index].flexible_spec[ind];
      if (!res.interests || !(res.interests instanceof Array)) res.interests = [];
      e.map((v) => {
        let index = res.interests.findIndex((int) => int.id == v.id);
        if (index < 0) {
          res.interests.push(v);
        } else {
          res.interests.splice(index, 1, v);
        }
      });
      this.$refs.flexibleSpec.map((v) => {
        if (v.flexibleIndex == ind && v.contentIndex == index) {
          v.appendOptionValues(e);
        }
      });
      this.$set(this.contentList[index].flexible_spec, ind, res);
      this.$nextTick(() => {
        this.contentList = JSON.parse(JSON.stringify(this.contentList));
      });
    },
    // 获取推荐广告语
    getChatGptData(val) {
      this.chatGptData = [];
      let request = {
        saleId: val,
        type: 2,
      };
      createAd(request).then((res) => {
        if (res.code === 0) {
          this.chatGptData = res.data;
        }
      });
    },
  },
  watch: {
    // 监听受众的个数，是一个的时候，不能删除
    editableTabs: {
      handler() {
        if (this.editableTabs.length > 1) {
          this.editable = true;
        } else {
          this.editable = false;
        }
      },
      deep: true,
    },
    initData: {
      handler() {
        this.setAudienceInfo();
      },
      deep: true,
    },
    contentList: {
      handler() {
        this.refreshData();
        this.setAudiencList();
      },
      deep: true,
    },
    audienceInfo: {
      handler() {
        let list = [];
        // console.log('audienceInfo', this.audienceInfo);
        // return
        let info = JSON.parse(JSON.stringify(this.audienceInfo));
        let relation = [];
        // "key1"与以下内容建立关系网络的用户
        //         "key2"与以下内容建立关系网络定位受众类似的人群
        //         "key3"排除与以下内容建立关系网络的用户
        if (info.connections) {
          relation.push('key1');
          info.connections = info.connections.map((v) => v.id);
        }
        if (info.friends_of_connections) {
          relation.push('key2');
          info.friends_of_connections = info.friends_of_connections.map((v) => v.id);
        }
        if (info.excluded_connections) {
          relation.push('key3');
          info.excluded_connections = info.excluded_connections.map((v) => v.id);
        }
        info.relation = relation;
        // console.log(info);
        // this.audienceInfo.flexible_spec.map((item, index) => {
        // console.log(item);
        //     item.listCon.map(item1 => {
        // console.log(item1);
        //         list.push(this.getTargetListDefault(index, {
        //             key: item1.key,
        //             name: item1.name
        //         }, 'flexible_spec'))
        //     })
        //     this.getTargetListDefault(index,item.wordsCat,'flexible_spec');
        //
        // })
        // this.audienceInfo.notPeople.map(item => {
        //     if(!item.wordsCat.key){
        //         return false
        //     }
        //     this.getTargetListDefault(0, item.wordsCat, 'notPeople');
        //     list.push(this.getTargetListDefault(0, item.wordsCat, 'flexible_spec'))
        // })
        // console.log('audienceInfo',info);
        // axios.all(list)
        //     .then(axios.spread(() => {
        // console.log('adviseList', this.adviseList);
        //         //当这两个请求都完成的时候会触发这个函数，两个参数分别代表返回的结果
        // 设置年龄范围
        this.contentList = [{ ...info }];
        this.setAgeList();
        this.contentList[this.activeIndex].age_min = info.age_min;
        this.contentList[this.activeIndex].age_max = info.age_max;
        //     }))
      },
      deep: true,
    },
    showMore() {
      if (this.showMore && !this.accountPagesList.length) {
        this.getAcoountPages(); //账户下的主页
      }
    },
    currentAddSaleId(val) {
      if (val) {
        this.getChatGptData(val);
      }
    },
    adsetId: {
      handler(val) {
        if (val) {
          if (this.type == 'DRAFT') return;
          findSaleByAdSetId({ adsetId: this.adsetId, accountId: this.accountId }).then((res) => {
            if (res.code === 0) {
              let saleId = res.data[0].saleId;
              this.getChatGptData(saleId);
            }
          });
        }
      },
      immediate: true,
    },
  },
  created() {
    this.addAudience('flexible_spec'); //一个默认受众
    if (this.initData.targeting) {
      // console.debug('abc',this.initData.targeting);
      this.setAudienceInfo();
    }
  },
  mounted() {
    this.getAudienceContent(); //受众
    this.getCountryHistory(); //账号历史记录
    // this.drawLine();
  },
};
</script>
<style lang="scss">
.el-select-group__wrap {
  display: block !important;
}

.audienceCover {
  position: fixed;
  right: 0;
  top: 30%;
  // padding: 20px;
  border: 1px solid rgb(200, 224, 255);
  cursor: pointer;
  background: #fff;
  border-radius: 6px;
  max-width: 260px;
  background-color: #f8fbff;

  // box-sizing: border-box;
  .freshCon {
    padding: 20px;
  }

  .num {
    color: #f55949;
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    line-height: 2;
  }

  p {
    font-size: 12px;
    color: #666;
    text-align: center;
  }
}

.audience {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  .previews {
    flex: 200px 0 0;
    background: #fff;
    background-color: rgb(248, 251, 255);
  }

  .audienceForm {
    flex: 1;
    background: #fff;
    margin-right: 30px;
    padding: 20px;
    overflow: hidden;
  }

  .shouzhong {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    font-size: 14px;
    color: #606266;
    line-height: 40px;

    .name {
      flex: 120px 0 0;
      text-align: right;
      vertical-align: middle;
      float: left;
      padding: 0 12px 0 0;
    }

    .shouzhongCon {
      flex: 1;
      overflow: hidden;

      .list {
        p {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .tools {
            font-size: 12px;
            color: #409eff;

            i {
              cursor: pointer;
            }

            span {
              margin: 10px;
              cursor: pointer;
            }
          }
        }

        .item {
          border: 1px solid #dddd;
          border-radius: 3px;
        }

        .conItem {
          padding: 10px;

          .tags {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            font-size: 12px;
            position: relative;

            .tools {
              position: absolute;
              top: -10px;
              right: 0px;

              i {
                font-size: 12px;
                margin: 5px;
                cursor: pointer;
              }
            }

            .title {
              flex: 80px 0 0;
              margin-right: 10px;
              text-align: right;
              color: #666;
              font-weight: bold;
            }

            .el-tag {
              margin-right: 10px;
              margin-bottom: 10px;
            }

            .tagList {
              flex: 1;
              overflow: hidden;
            }
          }
        }
      }
    }
  }

  .el-tabs--top.el-tabs--card > .el-tabs__header .el-tabs__item:last-child span.el-icon-close {
    display: none !important;
  }

  .el-tabs--card > .el-tabs__header .el-tabs__item:last-child {
    // padding-right: 0;
    // border: none;
    color: #333;
  }

  .el-tabs__new-tab {
    margin-right: 20px;
    border: none;
    outline: none;
    color: #666;
    width: 60px;
    font-size: 14px;
  }

  .el-tabs__new-tab {
    .el-icon-plus:before {
      content: '克隆受众';
    }
  }
}

.chatGpt-box {
  font-size: 12px;
  line-height: 20px;
  margin-top: 10px;
}
</style>
